<template>
    <div class="h-100 flex-fill position-relative d-flex flex-column auth-page">

        <img src="@/static/dog-left-right.png" class="position-absolute w-100"
            style="max-width:170px;top: 60px;opacity: 1;" />
        <img src="@/static/dog-right-left.png" class="position-absolute w-100"
            style="max-width:150px;right:0;bottom:0px;opacity: 1;" />

        <div class="position-absolute w-100 d-flex" style="top:30px">
            <img src="@/static/logo.png" class="mx-auto" style="max-width:200px" />
        </div>

        <div class="my-auto w-100 flex-fill d-flex flex-column" style="">
            <div class="my-auto">
                <div class="my-auto">
                    <b-carousel :interval="0" v-model="step" img-height="600">
                        <b-carousel-slide img-blank>
                            <div class="w-100 d-flex">
                                <img class="mx-auto" style="max-width: 180px;" src="@/static/white-logo.png" />
                            </div>
                            <h1 class="font-weight-bolder mt-2 text-white text-center">Welcome to Mongrela</h1>
                        </b-carousel-slide>
                        <b-carousel-slide img-blank>
                            <div class="w-100 d-flex">
                                <img class="mx-auto" style="max-width: 180px;" src="@/static/white-logo.png" />
                            </div>
                            <p class="text-white text-center h4 font-weight-normal mt-2">
                                Spreading love and warmth to all of our best friends. They needs nothing but love and care
                            </p>
                            <h1 class="font-weight-bolder mt-5 text-white text-center">Pet Goes Social</h1>
                        </b-carousel-slide>
                        <b-carousel-slide img-blank>
                            <div class="w-100 d-flex">
                                <img class="mx-auto" style="max-width: 180px;" src="@/static/white-logo.png" />
                            </div>
                            <p class="text-white text-center h4 font-weight-normal mt-2">
                                From chats to vets, and everything in between, we're all about giving our fur friends the best
                                care possible!
                                <br /><br />
                                We’ve got tails wagging and purrs here
                            </p>
                        </b-carousel-slide>
                        <b-carousel-slide img-blank>
                            <div class="w-100 d-flex">
                                <img class="mx-auto" style="max-width: 180px;" src="@/static/white-logo.png" />
                            </div>
                            <h1 class="font-weight-bolder mt-2 h3 text-white text-center">Now Lets Become Friend and Join Our
                                Big
                                Fur Family</h1>

                            <router-link to="/login" class="btn mt-3 px-3 font-weight-bolder"
                                style="background-color: #FFAD32;font-size: 15px;border-radius: 30px;color: #2A444B;">
                                Login Now
                            </router-link>
                        </b-carousel-slide>
                    </b-carousel>
                </div>
            </div>
        </div>

        <div class="position-absolute w-100 d-flex flex-column justify-content-center" style="bottom: 30px;gap:10px">

            <div class="d-flex align-items-center justify-content-center mt-auto" style="gap:10px" v-if="step != 3">
                <div :style="`width: 10px;height: 10px;background-color: ${item == step ? '#FFAD32' : '#D9D9D9'};border-radius: 50%;`"
                    v-for="item in steps">
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import { BCarousel, BCarouselSlide } from 'bootstrap-vue'
import Cookies from 'universal-cookie'  
const cookies = new Cookies()
import store from '@/store'

export default {
    components: {
        BCarousel, BCarouselSlide
    },
    computed: {
        user(){
            return store.state.auth.USER
        }
    },
    watch: {
        user(val){
            if(val?.user_role == 'service_provider'){
                this.$router.push({ name: 'bussiness' });
            } else {
                this.$router.push({ name: 'homepage' });
            }
        }
    },
    mounted(){
        if(cookies.get('token')){
            // this.$router.push('homepage')
            store.dispatch('auth/GetUserData')
        }
    },
    data() {
        return {
            step: 0,
            steps: [0, 1, 2, 3]
        }
    }
}

</script>

<style>
div[role='listitem'] {
    height: 400px !important;
}
</style> 